import CategoriaBar from "../CategoriesBar";
import TopBar from "../TopBar";
import { Link } from "react-router-dom";

import { useGetPedidoQuery } from "../../../AppStore/ApiSlice";
import { useEffect, useState } from "react";

export default function Registro() {
    const { data: PedidosAll, isError, isLoading, error } = useGetPedidoQuery();
    const [ NewPedidosAll, setNewPedidosAll ] = useState([]);


    useEffect(() => {
        if (!isLoading) {
            setNewPedidosAll([...PedidosAll].reverse());
        }
    }, [isLoading])

    if (isLoading) return <>Loading</>;

    function TranslateDate(e) {
        const date = new Date(e);
        const options = {
            weekday: "long",
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: "numeric",
            minute: "numeric"
        }
        return (date.toLocaleDateString("es-AR", options))
    }
    return (
        <>
            <TopBar />
            <CategoriaBar />
            <div className="w-3/4 mx-auto">
                <table>
                    <thead>
                        <tr>
                            <th className=" border-2 border-black w-[5%] pointer cursor-pointer">Cliente</th>
                            <th className=" border-2 border-black pointer cursor-pointer">Fecha Y Hora</th>
                            <th className=" border-2 border-black pointer cursor-pointer">Correo</th>
                            <th className=" border-2 border-black pointer cursor-pointer">Telefono</th>
                            <th className=" border-2 border-black pointer cursor-pointer">Importe</th>
                            <th className=" border-2 border-black pointer cursor-pointer">Delivery</th>
                            <th className=" border-2 border-black pointer cursor-pointer">Visto</th>
                            <th className=" border-2 border-black">Edicion</th>
                        </tr>
                    </thead>

                    <tbody>
                        {NewPedidosAll == undefined ? <div></div> : NewPedidosAll.map((ped, i) => {
                            return (

                                <tr key={i} className=" bg-white">
                                    <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">{ped.NombreCliente}</th>
                                    <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">{TranslateDate(ped.FechaCreacion)}</th>
                                    <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">{ped.Email}</th>
                                    <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">{ped.Telefono}</th>
                                    <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">${ped.CostoTotal}</th>
                                    <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black">{ped.ZonaEntrega}</th>
                                    <th className=" border-r-2 border-l-2 border-t-[1px] border-b-[1px] border-black"><input type="checkbox" readOnly checked={ped.Visto}></input></th>
                                    <th className=" border-b-[1px] border-black ">
                                        <Link to={`/admin/Registro/Pedido?id=${ped._id}`}>
                                            <button className=" bg-slate-400 hover:bg-slate-500 rounded-full w-[100px] text-white" >Ver</button>
                                        </Link>
                                    </th>
                                </tr>
                            )
                        })}
                    </tbody>

                </table>
            </div>
        </>
    )
}